import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import 'less/advancedLists.less';
import {MultiGrid} from 'components/MultiGrid';
import PageMetaData from 'components/PageMetaData';
import styled from 'styled-components';
import {Loader} from '@partssourceinc/react-ui-core';
import {useParams} from 'react-router-dom';
import {FormularyChangeConfirmation, RadioButtonFilter} from './';
import _ from 'lodash';
import 'less/VisualFormulary/visualFormulary.less';

const LoaderContainer = styled.div`
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: block;
    align-items: center;
    justify-content: center;

    div {
        display: block;
        left: calc(50% - 37.5px);
        position: absolute;
        top: 100px;
    }
    }
`;

function VisualFormulary() {
    const gridName = 'visualFormularyColumns';

    const [initialRules, setInitialRules] = useState([]);
    const [data, setData] = useState({activeCount: 0, rules: []});
    const [rules, setRules] = useState([]);
    const [expertRules, setExpertRules] = useState([]);
    const [columns, setColumns] = useState([]);
    const [view, setView] = useState('Grid');
    const [isLoading, setIsLoading] = useState(true);
    const [metrics, setMetrics] = useState({});
    const [facets, setFacets] = useState([]);
    const [toggledOnArray, setToggledOnArray] = useState([]);
    const [toggledOffArray, setToggledOffArray] = useState([]);
    const [dateFilter, setDateFilter] = useState(RadioButtonFilter.Annual);
    const {optimized} = useParams();
    const [selectedFacets, setSelectedFacets] = useState([]);
    const [consolidatedChanges, setConsolidatedChanges] = useState([]);
    const [showChangeConfirmation, setShowChangeConfirmation] = useState(false);

    const isImpersonation = useSelector(state => state.network.tokenInfo.isImpersonation);
    const editFormularyRules = useSelector(state => state.user.settings.editFormularyRules) || isImpersonation;    

    const gridKeys = [
        {
            id: 'company',
            fieldName: 'type',
            value: 'Company',
            tabDisplay: 'Company Rules',
        },
        {
            id: 'expert',
            fieldName: 'type',
            value: 'Expert',
            tabDisplay: 'Expert Recommendations',
        }, 
    ];    

    useEffect(() => {
        getColumns();
        getData();
    }, []);

    useEffect(() => {                
        let d = _.cloneDeep(data);
        let percentage = 1;

        switch (dateFilter) {
            case RadioButtonFilter.Annual:
                percentage = 1;
                break;
            case RadioButtonFilter.Quarter:
                percentage = .25;
                break;
            case RadioButtonFilter.Month:
                percentage = .0823;
                break;
            case RadioButtonFilter.EndOfYear:
                percentage = data.daysLeftInYearCalc;
                break;
        }

        const proFormaSavings = _.sumBy(data.rules.filter(x => x.isActive), 'proFormaSavings');
        const proposedFormaSavings = _.sumBy(data.rules.filter(x => !x.isActive), 'proFormaSavings');

        d.proFormaSavings = proFormaSavings * percentage;
        d.proposedFormaSavings = proposedFormaSavings * percentage;

        d.rules.forEach(r => r.proFormaSavings = r.proFormaSavings * percentage);

        const activeRules = d.rules.filter(x => x.isActive).length;
        const inActiveRules = d.rules.filter(x => !x.isActive).length;
     
        setMetrics({actualSavings: d.actualSavings, proFormaSavings: d.proFormaSavings, proposedFormaSavings: d.proposedFormaSavings, activeRules: activeRules, inActiveRules: inActiveRules});
        setRules(d.rules);
        setFacets(d.facets);     
    }, [data, dateFilter]);

    const getColumns = () => {
        axios
            .get(`/ShoppingService/api/v1/account/gridSetting/${gridName}`).then((x) => {
                setColumns(x.data.columns);
                setView(x.data.view);
            });
    }

    const getData = (currentFacets, onArray, offArray) => {
        setIsLoading(true);
        
        const request = {
            overrideOnRuleIds: (onArray || toggledOnArray).map(x => x.id),
            overrideOffRuleIds: (offArray || toggledOffArray).map(x => x.id),
            useOptimized: optimized || false,
            selectedFacets: currentFacets || selectedFacets,
        };

        if (initialRules.length === 0) {
            axios.post('/FormularyService/api/v1/report/visualformulary', request).then(x => {       
                setData(x.data);
                setIsLoading(false);
                setInitialRules(_.cloneDeep(x.data.rules));
                setExpertRules(x.data.expertRules);
            });
        } else { 
            request.rules = initialRules;
            axios.post('/FormularyService/api/v1/report/filtervisualformularyrules', request).then(x => {       
                let d = _.cloneDeep(data);
                d.rules = x.data.rules;
                d.facets = x.data.facets;
                setData(d);
                setIsLoading(false);
            });
        }
    }

    const onSelect = (rule) => {        
        if (rule.type === 'Company') window.open(`/Formulary/Evidence/${rule.ravenId}`, '_blank'); 
        else window.open(`/Formulary/Evidence/${rule.expertListRavenId}/true`, '_blank');       
    }

    const onRefresh = (fltr) => {   
        if (fltr && fltr.isReset) {
            setToggledOnArray([]);
            setToggledOffArray([]);            
            getData(null, [], []);
        } else { 
            setDateFilter(fltr); 
            let logValue = fltr === 'Through End of Year' ? 'EOY' : fltr;
            logEvent('VF Home Timeframe Filter', logValue);
        }        
    }  

    const onClearFilters = () => {        
        setSelectedFacets([]);        
        getData([]);
    }

    const onSwitch = (rule, checked) => {
        if (!(editFormularyRules)) return;

        let onArray = toggledOnArray;
        let offArray = toggledOffArray;
        
        if (checked) {
            const index = offArray.findIndex(x => x.id === rule.id);

            if (index >= 0) {
                offArray.splice(index, 1);
                setToggledOffArray(offArray);
            } else {
                onArray.push(rule);
                setToggledOnArray(onArray);
            }
           
        } else {
            const index = onArray.findIndex(x => x.id === rule.id);

            if (index >= 0) {
                onArray.splice(index, 1);
                setToggledOnArray(onArray);
            } else {
                offArray.push(rule);
                setToggledOffArray(offArray);
            }
        }

        getData();     
    }

    const onFormularyFacetChange = (facet) => {
        let currentFacets = selectedFacets;

        if (facet.isSelected) {
            currentFacets.push(facet);
            setSelectedFacets(currentFacets);
            logEvent('VF Facet Click', facet.facetName);
        } else {
            currentFacets = currentFacets.filter(f => !(f.facetName === facet.facetName && f.range === facet.range));
            setSelectedFacets(currentFacets);
        }

        getData(currentFacets);
    }

    const onSaveChanges = () => {

        setIsLoading(true);

        const request = {
            overrideOnRuleIds: toggledOnArray.map(x => x.id),
            overrideOffRuleIds: toggledOffArray.map(x => x.id),  
            expertRules: expertRules,                     
        };
        
        axios.post('/FormularyService/api/v1/report/SaveGridChanges', request).then(() => {                       
            let changes = [];

            toggledOnArray.forEach(x => {
                changes.push(`Change Rule ${x.name} - Inactive to <b>Active</b>`);
            });

            toggledOffArray.forEach(x => {
                changes.push(`Change Rule ${x.name} - Active to <b>Inactive</b>`);
            });

            setConsolidatedChanges(changes);
            setShowChangeConfirmation(true);
            setIsLoading(false);            
            setToggledOffArray([]);
            setToggledOnArray([]);
            setInitialRules(_.cloneDeep(rules));
        });                
    }
    
    const logEvent = (name, value) => {
        axios.post(`/FormularyService/api/v1/log/99/Visual Formulary`, [{name: name, value: value}]);
    }

    return (
        <div className="visual-formulary">
            <PageMetaData title="Formulary Rules" pageType="other" trackAnalytics={true} />
            {isLoading && <LoaderContainer> <Loader /> </LoaderContainer>}            
            <MultiGrid
                key={view}
                gridName={gridName}
                view={view}
                gridKeys={gridKeys}
                data={rules}
                columns={columns}
                label="My Account"
                subLabel="Formulary Rules"
                onSelect={onSelect}
                onRefresh={onRefresh}
                metrics={metrics}
                onSwitch={onSwitch}
                isVisualFormulary={true}
                disableCardView={false}
                defaultToCardView={true}
                hideLegend={true}
                facets={facets}
                onFormularyFacetChange={onFormularyFacetChange}
                gridEntityName="rules"
                pendingChanges={toggledOnArray.length + toggledOffArray.length}
                onSave={onSaveChanges}
                onClearFilters={onClearFilters}
                selectedFacets={selectedFacets}
            />
            {showChangeConfirmation && <FormularyChangeConfirmation showCustomerMessage={true} changes={consolidatedChanges} onHide={() => setShowChangeConfirmation(false)} />}
        </div>
    );
}

export default VisualFormulary;
